import { Search } from 'auto-libs'
import { Alert, Layout, Loading, Toast } from 'auto-ui'
import { useEffect, useState } from 'react'
import { useSetState } from 'react-use'
import FeedBack from '../../components/feedback'
import http from '../../utils/http'
import { aliPay, wxPay } from '../../utils/pay'
import { delHomePageCouponPop } from '../../utils/storage'
import { sleep } from '../../utils/time'
import ClassifyHeader from './components/classify-header'
import AdHehun from './imgs/ad-hehun.png'
import AdNameMatching from './imgs/ad-name.jpg'
import AdQinggan from './imgs/ad-qinggan.png'
import Banner from './imgs/banner.png'
import './style.scss'

export interface IState {
  loading: boolean
  errorMsg: string
  data: any
}

const PageIndex = () => {
  const [alipayForm, setAlipayForm] = useState<any>(null)
  const orderId = Search.get('orderNo') || ''

  const [state, setState] = useSetState<IState>({
    loading: true,
    errorMsg: '',
    data: { shiShen: {}, gan: {}, hideGan: {}, wuXing: {}, naYin: {} },
  })

  let timer: any = null
  let payWay = 'WXPAY'
  let alertCloseHandler: any = null

  const fetchMatchResult = async () => {
    try {
      if (!orderId) {
        Toast('地址不正确，请回首页重新测试')
        return
      }

      setState({
        loading: true,
      })

      await sleep(1500)

      const payResult: any = await http.request({
        url: '/order/queryStatus',
        method: 'POST',
        data: {
          orderId,
        },
      })

      let { payMethod, status } = payResult.body
      if (payResult.code !== '00000000' || status !== 2) {
        payWay = payMethod
        checkPayStatus()
        return
      }

      const result: any = await http.request({
        url: 'result/queryMarriage',
        method: 'POST',
        data: {
          orderId,
        },
      })

      delHomePageCouponPop()

      const { body } = result

      setState({ data: body })
    } catch (e: any) {
      setState({ errorMsg: e.message || '系统异常，请重试' })
    } finally {
      setState({
        loading: false,
      })
    }
  }

  const onWechatPay = async () => {
    const result = await wxPay(orderId)
    window.location.href = result
  }

  const onAlipay = async () => {
    const result = await aliPay(orderId)

    setAlipayForm(result)

    setTimeout(() => {
      document.forms[0].submit()
    }, 100)
  }

  const toPay = async () => {
    try {
      Loading()
      if (payWay === 'WXPAY') {
        await onWechatPay()
      } else {
        await onAlipay()
      }
    } catch (error: any) {
      Toast(error.message || '系统异常，请重新再试')
    } finally {
      Loading.hide()
    }
  }

  const checkPayStatus = () => {
    const [close] = Alert({
      title: '请确认支付是否完成',
      btnDirection: 'column',
      btns: [
        {
          name: '已完成支付',
          type: 'danger',
          hollow: true,
          onClick: () => {
            fetchMatchResult()
          },
        },
        {
          name: '支付遇到问题，重新支付',
          type: 'danger',
          onClick: () => {
            toPay()
          },
        },
      ],
    })

    alertCloseHandler = close
  }

  useEffect(() => {
    fetchMatchResult()

    return () => {
      timer && clearTimeout(timer)

      alertCloseHandler && alertCloseHandler()
    }
  }, [])

  return (
    <Layout className="page-result" useBottomSafeArea={false}>
      <Layout.Body className="body" loading={state.loading} errorInfo={state.errorMsg}>
        <div className="banner">
          <img src={Banner} />
        </div>

        <div className="base-info">
          <div className="base">
            <div className="title">姓名：</div>
            <div className="value">{state.data.name}</div>
          </div>
          <div className="base">
            <div className="title">性别：</div>
            <div className="value">{state.data.gender}</div>
          </div>
          <div className="base">
            <div className="title">属相：</div>
            <div className="value">{state.data.zodiac}</div>
          </div>
          <div className="base">
            <div className="title">公历：</div>
            <div className="value">{state.data.gregorian}</div>
          </div>
          <div className="base">
            <div className="title">农历：</div>
            <div className="value">{state.data.lunar}</div>
          </div>
        </div>

        <div className="bazi">
          <div className="title">
            <img src={require('./imgs/tit01.png')} />
          </div>
          <div className="content">
            <table width="100%">
              <tbody>
                <tr>
                  <th>&nbsp;</th>
                  <th>年柱</th>
                  <th>月柱</th>
                  <th>日柱</th>
                  <th>时柱</th>
                </tr>
                <tr>
                  <th>十神</th>
                  <td>{state.data.shiShen.year}</td>
                  <td>{state.data.shiShen.month}</td>
                  <td>{state.data.shiShen.day}</td>
                  <td>{state.data.shiShen.time}</td>
                </tr>
                <tr>
                  <th>干支</th>
                  <td>{state.data.gan.year}</td>
                  <td>{state.data.gan.month}</td>
                  <td>{state.data.gan.day}</td>
                  <td>{state.data.gan.time}</td>
                </tr>
                <tr>
                  <th>藏干</th>
                  <td>{state.data.hideGan.year}</td>
                  <td>{state.data.hideGan.month}</td>
                  <td>{state.data.hideGan.day}</td>
                  <td>{state.data.hideGan.time}</td>
                </tr>
                <tr>
                  <th>纳音</th>
                  <td>{state.data.naYin.year}</td>
                  <td>{state.data.naYin.month}</td>
                  <td>{state.data.naYin.day}</td>
                  <td>{state.data.naYin.time}</td>
                </tr>
                <tr>
                  <th>五行</th>
                  <td>{state.data.wuXing.year}</td>
                  <td>{state.data.wuXing.month}</td>
                  <td>{state.data.wuXing.day}</td>
                  <td>{state.data.wuXing.time}</td>
                </tr>
              </tbody>
            </table>

            <div className="ys">
              <div className="ys-item">
                <span className="b">身宫：</span>
                <span className="v">{state.data.shenGong}</span>
              </div>
              <div className="ys-item">
                <span className="b">胎元：</span>
                <span className="v">{state.data.taiYuan}</span>
              </div>
              <div className="ys-item">
                <span className="b">命宫：</span>
                <span className="v">{state.data.mingGong}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="result-list">
          <div className="result-item">
            <div className="title">
              <img src={require('./imgs/tit02.png')} />
            </div>
            <div className="content">{state.data.explain1}</div>
          </div>
          <div className="result-item">
            <div className="title">
              <img src={require('./imgs/tit03.png')} />
            </div>
            <div className="content">{state.data.explain2}</div>
          </div>
          <div className="result-item">
            <div className="title">
              <img src={require('./imgs/tit04.png')} />
            </div>
            <div className="content">{state.data.explain3}</div>
          </div>
          <div className="result-item">
            <div className="title">
              <img src={require('./imgs/tit05.png')} />
            </div>
            <div className="content">{state.data.explain4}</div>
          </div>
          <div className="result-item">
            <div className="title">
              <img src={require('./imgs/tit06.png')} />
            </div>
            <div className="content">{state.data.explain5}</div>
          </div>
        </div>

        <div className="ads">
          <ClassifyHeader title="更多好玩" />

          <a className="ad qinggan" href="	https://qinggan.ijianshui.com?channel=3bvs$70">
            <img src={AdQinggan} />
          </a>

          <a className="ad name" href="https://l1.ijianshui.com/?channel=j4xu$52">
            <img src={AdNameMatching} />
          </a>

          <a className="ad hehun" href="https://hehun.ijianshui.com?channel=x27e$65">
            <img src={AdHehun} />
          </a>
        </div>

        <FeedBack orderNo={orderId} />
      </Layout.Body>
      <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: alipayForm }}
      />
    </Layout>
  )
}

export default PageIndex
