import React from 'react'
import './style.scss'

const dataTags = [
  '算得很准(3.1万)',
  '婚恋必备(2.3万)',
  '很专业(2.2万)',
  '非常正规(1.8万)',
  '有依据(1.5万)',
  '分析很好(1.3万)',
]

const dataComment = [
  {
    avatar: './imgs/tx01.jpg',
    name: '刘*紫',
    time: '5天前',
    desc: '分析的特别详细，还有专业的老师进行分析。',
    liulan: '浏览19969次',
    zan: '8909',
  },
  {
    avatar: './imgs/tx02.jpg',
    name: '刘*云',
    time: '6天前',
    desc: '真的很不错，感觉非常的契合我自身的一些情况，给个好评。',
    liulan: '浏览18069次',
    zan: '7892',
  },
  {
    avatar: './imgs/tx03.jpg',
    name: '赵*达',
    time: '5小时前',
    desc: '我和我朋友都测试过了，确实感觉说的都挺准的，建议也很详尽，必须好评。',
    liulan: '浏览19969次',
    zan: '7690',
  },
  {
    avatar: './imgs/tx04.jpg',
    name: '张*开',
    time: '4小时前',
    desc: '这里的老师都很专业，我发现他们讲的也真的是有理有据，不得不佩服。',
    liulan: '浏览13969次',
    zan: '6843',
  },
  {
    avatar: './imgs/tx05.jpg',
    name: '李*起',
    time: '12小时前',
    desc: '这个测试真的挺公正中肯，可信度还是挺高的。',
    liulan: '浏览23969次',
    zan: '5899',
  },
  {
    avatar: './imgs/tx06.jpg',
    name: '李*',
    time: '1小时前',
    desc: '给个好评，服务都很好，分析我的性格一些外貌都很准，分析我的另一半时也是很有根据。',
    liulan: '浏览13269次',
    zan: '4812',
  },
  {
    avatar: './imgs/tx07.jpg',
    name: '王*',
    time: '30分钟前',
    desc: '哇塞！没想到我抱着试一试的想法，结果这么精确精准又专业，这个婚姻测试太牛了，感谢感谢！',
    liulan: '浏览9969次',
    zan: '3833',
  },
  {
    avatar: './imgs/tx08.jpg',
    name: '蒋*超',
    time: '1小时前',
    desc: '着急脱单，在婚姻测试里找到了专业的老师进行测试，了解到自己的另一半，希望可以找到阿。',
    liulan: '浏览7969次',
    zan: '3899',
  },
  {
    avatar: './imgs/tx09.jpg',
    name: '沈*涛',
    time: '5小时前',
    desc: '真的很不错，感觉非常的契合我自身的一些情况，给个好评。',
    liulan: '浏览4969次',
    zan: '2899',
  },
  {
    avatar: './imgs/tx10.jpg',
    name: '杨*远',
    time: '2小时前',
    desc: '确实感觉说的都挺准的，感谢。',
    liulan: '浏览5969次',
    zan: '1899',
  },
]

export default function FakeComment() {
  return (
    <div className="com-fake-comment">
      <div className="inner">
        <div className="title">好评如潮</div>
        <div className="tags">
          {dataTags.map((item: string, index: number) => (
            <div className="tag" key={index}>
              {item}
            </div>
          ))}
        </div>
        <div className="scroller">
          <div className="list w1">
            {dataComment.map((item: any, index: number) => {
              return (
                <div className="comment" key={index}>
                  <div className="head">
                    <div className="avatar">
                      <img src={require(`${item.avatar}`)} />
                    </div>
                    <div className="name">{item.name}</div>
                    <div className="time">{item.time}</div>
                  </div>

                  <div className="desc">{item.desc}</div>

                  <div className="data">
                    <div className="liulan">{item.liulan}</div>
                    <div className="zan">{item.zan}</div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="list w2">
            {dataComment.map((item: any, index: number) => {
              return (
                <div className="comment" key={index}>
                  <div className="head">
                    <div className="avatar">
                      <img src={require(`${item.avatar}`)} />
                    </div>
                    <div className="name">{item.name}</div>
                    <div className="time">{item.time}</div>
                  </div>

                  <div className="desc">{item.desc}</div>

                  <div className="data">
                    <div className="liulan">{item.liulan}</div>
                    <div className="zan">{item.zan}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
