import React from 'react'
import './style.scss'

export default function FakeCommentLite() {
  const data = [
    {
      time: '5秒前',
      province: '上海',
      name: '*成',
      score: '99分好评',
    },
    {
      time: '15秒前',
      province: '广东',
      name: '刘*云',
      score: '98分好评',
    },
    {
      time: '37秒前',
      province: '江苏',
      name: '杨*元',
      score: '100分好评',
    },
    {
      time: '45秒前',
      province: '新疆',
      name: '许*银',
      score: '98分好评',
    },
    {
      time: '1分钟前',
      province: '云南',
      name: '付*成',
      score: '99分好评',
    },
    {
      time: '1分钟前',
      province: '云南',
      name: '刘*非',
      score: '100分好评',
    },
    {
      time: '2分钟前',
      province: '贵州',
      name: '张*浩',
      score: '100分好评',
    },
    {
      time: '2分钟前',
      province: '新疆',
      name: '沈*涛',
      score: '98分好评',
    },
    {
      time: '3分钟前',
      province: '上海',
      name: '赵*达',
      score: '99分好评',
    },
    {
      time: '5分钟前',
      province: '北京',
      name: '李*',
      score: '100分好评',
    },
    {
      time: '5分钟前',
      province: '天津',
      name: '张*剑',
      score: '100分好评',
    },
    {
      time: '10分钟前',
      province: '湖南',
      name: '李*育',
      score: '98分好评',
    },
    {
      time: '15分钟前',
      province: '河北',
      name: '*敏',
      score: '99分好评',
    },
    {
      time: '15分钟前',
      province: '河南',
      name: '周*超',
      score: '97分好评',
    },
    {
      time: '16分钟前',
      province: '河北',
      name: '*超',
      score: '99分好评',
    },
    {
      time: '18分钟前',
      province: '浙江',
      name: '张*超',
      score: '100分好评',
    },
    {
      time: '19分钟前',
      province: '江苏',
      name: '张*澄',
      score: '99分好评',
    },
    {
      time: '25分钟前',
      province: '陕西',
      name: '李*',
      score: '96分好评',
    },
    {
      time: '25分钟前',
      province: '陕西',
      name: '邵*宏',
      score: '100分好评',
    },
    {
      time: '34分钟前',
      province: '黑龙江',
      name: '周*',
      score: '99分好评',
    },
    {
      time: '45分钟前',
      province: '新疆',
      name: '陈*予',
      score: '100分好评',
    },
    {
      time: '45分钟前',
      province: '云南',
      name: '王*旭',
      score: '100分好评',
    },
    {
      time: '1小时前',
      province: '上海',
      name: '赵*',
      score: '98分好评',
    },
    {
      time: '1小时前',
      province: '河南',
      name: '*超',
      score: '99分好评',
    },
  ]

  return (
    <div className="com-fake-comment-lite">
      <div className="marquee">
        <div className="inner">
          {data.map((item: any, index: number) => (
            <div className="item" key={index}>
              <span>{item.time}</span>
              <span>{item.province}</span>
              <span>{item.name}</span>
              <span>{item.score}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
