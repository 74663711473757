import { Search } from 'auto-libs'
import { Button, Layout, Loading, Popup, Toast } from 'auto-ui'
import { useEffect, useState } from 'react'
import { wxPay } from '../../utils/pay'
import { getCountDown } from '../../utils/time'
import FakeBubble from './components/fake-buble'
import FakeComment from './components/fake-comment'
import LOCKBanner from './imgs/xqs.png'
import './style.scss'

const PagePay = () => {
  const [time, setTime] = useState(5 * 60 - 1)

  // const [alipayForm, setAlipayForm] = useState<any>(null)
  const [showFooterBtn, setShowFooterBtn] = useState(false)
  const [payVisible, setPayVisible] = useState(false)
  const { orderNo } = Search.parse()
  let timer: any = null

  const countdown = (time: number, f: any) => {
    let _time = time
    timer = setTimeout(() => {
      --_time
      if (_time === 0) {
        clearTimeout(_time)
        return
      }
      f(_time)
      countdown(_time, f)
    }, 1000)
  }

  const onWechatPay = async (discount?: string) => {
    try {
      Loading()
      const result = await wxPay(orderNo, discount)
      window.location.href = result
    } catch (error: any) {
      Toast(error.message || '系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  // const onAlipay = async (discount?: string) => {
  //   try {
  //     Loading()
  //     const result = await aliPay(orderNo, discount)

  //     setAlipayForm(result)

  //     setTimeout(() => {
  //       document.forms[0].submit()
  //     }, 100)
  //   } catch (error) {
  //     Toast('系统异常，请重试')
  //   } finally {
  //     Loading.hide()
  //   }
  // }

  const onBodyScroll = (e: any) => {
    if (e.target.scrollTop > 550) {
      setShowFooterBtn(true)
    } else {
      setShowFooterBtn(false)
    }
  }

  const onPayButton = () => {
    setPayVisible(true)
  }

  useEffect(() => {
    countdown(5 * 60 - 1, setTime)

    return () => {
      timer && clearTimeout(timer)
    }
  }, [])

  return (
    <Layout className="page-pay">
      <Layout.Body className="body" onScroll={onBodyScroll}>
        <div className="banner">
          <div className="title">您的姻缘报告已经生成</div>
          <div className="cc">
            <div className="cc-item c1" />
            <div className="cc-item c2" />
            <div className="cc-item c3" />
          </div>
          <div className="cc">
            <div className="cc-item c4" />
            <div className="cc-item c5" />
            <div className="cc-item c6" />
            <div className="cc-item c7" />
          </div>

          <div className="xx-qaaaa">
            <p>Ta对你是真心的吗？</p>
            <p>你和Ta未来会有好结果吗？</p>
            <p>一生会有几段桃花姻缘？如何把握？</p>
            <p className="tip">
              已有<em>469006</em>人领取报告，好评率高达<em>99.96%</em>
            </p>
          </div>

          <div className="price">
            吉时特价：<em>￥19.9</em>
            <del>169元</del>
          </div>

          <div className="countdown">倒计时：{getCountDown(time)}</div>

          <div className="btns">
            <Button className="submit" onClick={onPayButton}>
              领取姻缘报告
              <sub />
            </Button>

            <div className="safe">
              <img src={require('./imgs/anquan.png')} />
            </div>
          </div>
        </div>

        <div className="result-list lock">
          <img src={LOCKBanner} />
        </div>

        <div className="comment">
          <FakeComment />
        </div>

        <div className="more">
          <h2>资深大师 匠心巨制</h2>
          <div className="company">上海简水网络科技有限公司</div>
          <div className="cx" />
        </div>
      </Layout.Body>

      <Layout.Footer className="footer">
        {showFooterBtn && (
          <div className="btns">
            <Button className="submit" onClick={onPayButton}>
              领取姻缘报告
              <sub />
            </Button>
          </div>
        )}
      </Layout.Footer>

      <FakeBubble />

      {/* <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: alipayForm }}
      /> */}

      <Popup
        className="pop-pay"
        visible={payVisible}
        height="320px"
        onMaskClick={() => setPayVisible(false)}
      >
        <a className="close" onClick={() => setPayVisible(false)}>
          关闭
        </a>
        <div className="xx-qaaaa">
          <p>Ta对你是真心的吗？</p>
          <p>你和Ta未来会有好结果吗？</p>
          <p>一生会有几段桃花姻缘？如何把握？</p>
          <p className="tip">
            已有<em>469006</em>人领取报告，好评率高达<em>99.96%</em>
          </p>
        </div>

        <div className="pay-button">
          <Button className="btn wechat" onClick={() => onWechatPay()}>
            <span className="icon" />
            微信支付
          </Button>
          {/* <Button className="btn alipay" onClick={() => onAlipay()}>
            <span className="icon" />
            支付宝支付
          </Button> */}
        </div>
      </Popup>
    </Layout>
  )
}

export default PagePay
