import Cookies from 'js-cookie'

export const getUserId = () => Cookies.get('__app__userId__')
export const setUserId = (value: string) => {
  Cookies.set('__app__userId__', value, { domain: 'ijianshui.com', path: '', expires: 365 })
}

export const getHomePageCouponPop = () => localStorage['__app__marriage__homepage__coupon__']
export const setHomePageCouponPop = (value: string) => {
  if (!value) {
    return
  }
  localStorage['__app__marriage__homepage__coupon__'] = value
}
export const delHomePageCouponPop = () =>
  localStorage.removeItem('__app__marriage__homepage__coupon__')

export const getXNameTest = () => localStorage['__x_marriage_name_test__']
export const setXNameTest = (value: string) => {
  if (!value) {
    return
  }
  localStorage['__x_marriage_name_test__'] = value
}
export const delXNameTest = () => localStorage.removeItem('__x_marriage_name_test__')
